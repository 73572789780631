
import { defineComponent } from "vue";

export default defineComponent({
  name: "Previews",
  data() {
    return {
      mode: "light" as "light" | "dark" | "double",
      fast: false,
      lightVid: null as unknown as HTMLVideoElement,
      darkVid: null as unknown as HTMLVideoElement,
    };
  },
  mounted() {
    this.lightVid = this.$refs.light as HTMLVideoElement;
    this.darkVid = this.$refs.dark as HTMLVideoElement;
  },
  methods: {
    timeUpdated(e: Event) {
      const video = e.target as HTMLVideoElement;
      const video2 = video == this.lightVid ? this.darkVid : this.lightVid;
      if (e.type == "pause") {
        video2.pause();
      } else if (e.type == "play") {
        video2.play();
      } else {
        const diff = video.currentTime - video2.currentTime;
        if (e.type == "timeupdate" && Math.abs(diff) > 0.5) {
          video2.currentTime = video.currentTime;
        }
      }
    },
    changeSpeed() {
      this.fast = !this.fast;
      this.lightVid.playbackRate = this.darkVid.playbackRate = this.fast
        ? 2
        : 1;
    },
  },
});
