
import { CSSProperties, defineComponent } from "vue";
import Wrapper from "./components/Wrapper.vue";
import Previews from "./components/Previews.vue";

export default defineComponent({
  name: "App",
  components: { Wrapper, Previews },
  data() {
    return {
      scrolling: false,
      rectangles: [] as CSSProperties[],
      speed: 20000,
    };
  },
  mounted() {
    for (let i = 0; i < 10; i++) this.updateRect(i, true);
    const path = localStorage.getItem("path");
    if (path) {
      localStorage.removeItem("path");
      this.$router.replace(path);
    }
  },
  methods: {
    updateRect(i: number, init?: boolean) {
      const size = this.rand(30, 150);
      const bottomPerc = this.rand(0, 100);
      const bottom = init ? bottomPerc + "%" : -size + "px";
      const transition =
        this.rand(this.speed, this.speed * 2) *
        (init ? 1 - bottomPerc / 100 : 1);
      this.rectangles[i] = { bottom, opacity: "1" };
      setTimeout(() => {
        this.rectangles[i] = {
          bottom: "100%",
          opacity: "0",
          left: this.rand(-10, 100) + "%",
          width: size + "px",
          height: size + "px",
          borderRadius: this.rand(size / 10, size / 3) + "px",
          animation: "rotate " + size * 100 + "ms linear infinite",
          transition: `bottom ${transition}ms ease-out, opacity ${
            transition * 0.2
          }ms ${transition * 0.5}ms`,
        };
        setTimeout(() => this.updateRect(i), transition);
      }, 100);
    },
    rand(min: number, max: number) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
  },
});
