
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  mounted() {
    if (this.$route.hash)
      setTimeout(() => this.$router.replace({ hash: "#jupiiiii" }), 6000);
  },
});
