
import { defineComponent } from "vue";

export default defineComponent({
  name: "Wrapper",
  data() {
    return {
      scrollTop: 0,
      message: "",
      importantMessage: "",
    };
  },
  mounted() {
    document.body.addEventListener(
      "scroll",
      () => (this.scrollTop = document.body.scrollTop),
    );
    fetch("https://ednevnik.plus/users.html")
      .then((response) => response.text())
      .then((html) => (this.message = html));
    fetch("https://ednevnik.plus/important.html")
      .then((response) => response.text())
      .then((html) => {
        if (!html.startsWith("<script>")) this.importantMessage = html;
      });
  },
});
