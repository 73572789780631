import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Wrapper = _resolveComponent("Wrapper")!
  const _component_Previews = _resolveComponent("Previews")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Wrapper),
    (_ctx.$route.path == '/')
      ? (_openBlock(), _createBlock(_component_Previews, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_1, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.rectangles, (rect, i) => {
        return (_openBlock(), _createBlock("div", {
          key: i,
          class: "rect",
          style: rect
        }, null, 4))
      }), 128))
    ])
  ], 64))
}