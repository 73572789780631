
import { defineComponent } from "vue";

export default defineComponent({
  name: "Changelog",
  data() {
    return {
      selectedApp: 0 as 0 | 1,
    };
  },
});
